














































































































































































































import {
  pickupStatus,
  SortFields,
  SortType,
} from '@/app/infrastructures/misc/Constants/pickupRadiusValidation'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import DateTimePickerV2, {
  IDateRangeValue,
} from '@/app/ui/components/DateTimePickerV2/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import {
  ICancelListParameters,
  ICancelListParams,
  IHeaderCell,
  IOptions,
  ITableCell,
  IValidationStatusMapper,
} from '@/data/infrastructures/misc/interfaces/cancelValidation'
import { Component, Vue, Watch } from 'vue-property-decorator'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import { Utils } from '@/app/infrastructures/misc/Utils'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { Cancel } from '@/domain/entities/CancelValidation'
import Badge from '@/app/ui/components/Badge/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import controller from '@/app/ui/controllers/CancelValidationController'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import Button from '@/app/ui/components/Button/index.vue'

interface Summary {
  fraud: number
  needValidation: number
  valid: number
}

@Component({
  components: {
    DateTimePickerV2,
    DropdownSelect,
    TextInput,
    DataTableV2,
    AscendingIcon,
    LoadingOverlay,
    Badge,
    PaginationNav,
    EmptyState,
    Button,
  },
})
export default class CancelList extends Vue {
  controller = controller

  enumSelectedSort = SortType
  enumSortFields = SortFields
  enumPickupStatus = pickupStatus

  selectedSort: SortType | null = null
  tableData: ITableCell[][] = []

  statusOptions: IOptions[] = [
    {
      label: 'Semua',
      value: '',
    },
    {
      label: 'Valid',
      value: '1',
    },
    {
      label: 'Fiktif',
      value: '2',
    },
    {
      label: 'Need Validation',
      value: '0',
    },
  ]

  datepickerPresets = [
    {
      key: 'last31days',
      label: '31 Hari Terakhir',
      value: 31,
    },
  ]

  parameters: ICancelListParameters = {
    page: 1,
    perPage: 50,
    search: '',
    status: this.statusOptions[0],
    dateRange: {
      start: new Date(),
      end: new Date(),
    },
  }
  headers = [
    this.headerCellMapper('No.', '60px'),
    this.headerCellMapper('Shipment ID', '160px'),
    this.headerCellMapper('History ID', '160px'),
    this.headerCellMapper('Nama Kurir', '220px'),
    this.headerCellMapper('Waktu Cancel', '188px'),
    this.headerCellMapper('Alasan Cancel', '188px'),
    this.headerCellMapper('Status', '140px', 'center'),
    this.headerCellMapper('Aksi', '140px', 'center'),
  ]

  listData: ITableCell[][] = []
  summary: Summary = {
    fraud: 0,
    needValidation: 0,
    valid: 0,
  }

  created(): void {
    document.title = 'List Cancel | Content Management System | Lionparcel'
    this.getCancelListPickup(true)
  }

  get params(): ICancelListParams {
    return {
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      keyword: this.parameters.search,
      status: this.parameters.status.value,
      startDate: <string>(
        Utils.formatDateWithIDLocale(
          <string>this.parameters.dateRange?.start?.toISOString(),
          'YYYY-MM-DD'
        )
      ),
      endDate: <string>(
        Utils.formatDateWithIDLocale(
          <string>this.parameters.dateRange?.end?.toISOString(),
          'YYYY-MM-DD'
        )
      ),
      column: this.selectedSort ? this.selectedSort.split('-')[0] : '',
      sortDirection: this.selectedSort ? this.selectedSort.split('-')[1] : '',
    }
  }

  public getCancelListPickup(reset?: boolean): void {
    if (reset) {
      this.parameters.page = 1
    }
    this.controller.getCancelList(this.params)
  }

  public onSearch = Utils.debounce((keyword: string) => {
    if (keyword.length === 0 || keyword.length > 2) {
      this.parameters.search = keyword
      this.getCancelListPickup(true)
    }
  }, 500)

  public onChangeStatus(status: IOptions): void {
    if (!status) {
      this.parameters.status = this.statusOptions[0]
    }
    this.getCancelListPickup(true)
  }

  public onChangeDateRange(value: IDateRangeValue): void {
    if (!value.start && !value.end) {
      this.parameters.dateRange = {
        start: new Date(),
        end: new Date(),
      }
    }
    this.getCancelListPickup(true)
  }

  public onTableSort(sortType: SortFields): void {
    switch (sortType) {
      case SortFields.COURIER_NAME:
        if (this.selectedSort === SortType.COURIER_NAME_ASC) {
          this.selectedSort = SortType.COURIER_NAME_DESC
        } else if (this.selectedSort === SortType.COURIER_NAME_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortType.COURIER_NAME_ASC
        }
        break
      case SortFields.CREATED_AT:
        if (this.selectedSort === SortType.CREATED_AT_ASC) {
          this.selectedSort = SortType.CREATED_AT_DESC
        } else if (this.selectedSort === SortType.CREATED_AT_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortType.CREATED_AT_ASC
        }
        break
    }
    this.getCancelListPickup(true)
  }

  public onExport(): void {
    const payload: Record<string, string | number> = {
      keyword: this.parameters.search,
      status: <string>this.parameters.status.value,
      startDate: <string>(
        Utils.formatDateWithIDLocale(
          <string>this.parameters.dateRange?.start?.toISOString(),
          'YYYY-MM-DD'
        )
      ),
      endDate: <string>(
        Utils.formatDateWithIDLocale(
          <string>this.parameters.dateRange?.end?.toISOString(),
          'YYYY-MM-DD'
        )
      ),
      filename: `CRRCNC-${Utils.formatDateWithIDLocale(
        new Date().toISOString(),
        'YYYYMMDDHHmmss'
      )}`,
    }

    this.controller.exportCancelList(payload)
  }

  public validationStatusMapper(status: string): IValidationStatusMapper {
    switch (status) {
      case this.enumPickupStatus.VALID:
        return {
          text: 'Valid',
          color: 'success',
        }
      case this.enumPickupStatus.NEED_VALIDATION:
        return {
          text: 'Need<br>Validation',
          color: 'warning',
        }
      default:
        return {
          text: 'Fiktif',
          color: 'error-1',
        }
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string,
    textAlign?: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
        textAlign: textAlign,
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string,
    justifyContent?: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
        display: 'flex',
        justifyContent: justifyContent || '',
      },
    }
  }

  @Watch('controller.cancelListData')
  onSetCancelListData(data: Cancel[]): void {
    this.tableData = data.map((cancel, index) => {
      return [
        this.tableCellMapper(
          index +
            1 +
            this.parameters.perPage * (this.parameters.page - 1) +
            '.',
          '60px',
          'center'
        ),
        this.tableCellMapper(
          cancel.bookingId
            ? <string[]>[cancel.shipmentId, cancel.bookingId]
            : <string[]>[cancel.shipmentId],
          '160px'
        ),
        this.tableCellMapper(<number>cancel.historyId, '160px'),
        this.tableCellMapper(
          `[${cancel.courierId}] ${cancel.courierName} </br> (${cancel.courierTypeValid}) ${cancel.courierPhoneNumber}`,
          '220px'
        ),
        this.tableCellMapper(
          <string>(
            Utils.formatTimeZone(
              Utils.formatDateWithIDLocale(
                <string>cancel.createdAt,
                'DD MMM YYYY HH:mm Z'
              )
            )
          ),
          '188px'
        ),
        this.tableCellMapper(<string>cancel.cancelReason, '188px'),
        this.tableCellMapper(<string>cancel.cancelStatus, '140px', 'center'),
        this.tableCellMapper(<number>cancel.historyId, '140px', 'center'),
      ]
    })
  }

  @Watch('controller.cancelSummaryData')
  onSetCancelSummaryData(data: Summary): void {
    this.summary = data
  }
}
